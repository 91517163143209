// Recommended way by docs to set styles for react-select library
export const reactSelectStyles = {
	control: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: "transparent",
		borderColor: "#2b3553",
	}),
	menu: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: "#f0f0f0",
		color: "#333333",
	}),
	singleValue: (baseStyles, state) => ({
		...baseStyles,
		color: "#f0f0f0",
	}),
};
