import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
	Alert,
	Button,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	Spinner,
} from "reactstrap";

// core components
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import FormInput from "components/Forms/FormInput";
import { toast } from "react-toastify";

import axios from "../../utils/Axios";
import { reactSelectStyles } from "../../consts";

export default function SignUpTicket() {
	const {
		register,
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors },
	} = useForm();
	const [shopEmail, setShopEmail] = useState("");
	const [userList, setUserList] = useState({ values: [] });
	// const [validationCode, setValidationCode] = useState("");
	const [validationCodeError, setValidationCodeError] = useState("");
	const [validationCodeValid, setValidationCodeValid] = useState(false);
	const [scannerSerial, setScannerSerial] = useState("");
	const [scannerSerialError, setScannerSerialError] = useState("");
	const [scannerSerialValid, setScannerSerialValid] = useState(false);
	const [formSuccess, setFormSuccess] = useState(false);
	const [loadingSpinner, setLoadingSpinner] = useState(false);

	const wrapper = React.useRef(null);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		wrapper.current.scrollTop = 0;
		document.body.classList.add("account-settings");
		return function cleanup() {
			document.body.classList.remove("account-settings");
		};
	}, []);

	function checkValidationCode(e) {
		setValue("code", e.target.value);
		const validationCode = getValues("code");
		axios
			.post("/activation/register_company_shop_users", {
				code: validationCode,
			})
			.then((res) => {
				setValidationCodeValid(true);
			})
			.catch((error) => {
				if (error.response.status === 500) {
					setValidationCodeError("Error: Activation code length is incorrect");
					toast.error("Error: Activation code length is incorrect");
				}

				if (error.response.status === 403) {
					setValidationCodeError(
						"Invalid Activation code entered, please try again"
					);
					toast.error("Invalid Activation code entered, please try again");
				}

				setValidationCodeValid(false);
			});
	}

	function onSubmit(data) {
		var backendData = data;
		backendData.users = userList.values;
		setLoadingSpinner(true);
		axios
			.post("/activation/register_company_shop_users", backendData)
			.then((res) => {
				toast.success("Sign up successful!");
				document.getElementById("form").reset();
				setShopEmail("");
				setUserList({ values: [] });
				setValidationCodeError("");
				setValidationCodeValid(false);
				setFormSuccess(true);
				window.scrollTo(0, 0);
				setLoadingSpinner(false);
			})
			.catch((error) => {
				toast.error(`Error: ${error.response.data}`);
				setFormSuccess(false);
				setLoadingSpinner(false);
			});
	}

	function addClick() {
		setUserList((prevState) => ({
			values: [
				...prevState.values,
				{ firstName: "", lastName: "", email: "", jobTitle: "Estimator" },
			],
		}));
	}

	function removeClick(i) {
		let values = [...userList.values];
		values.splice(i, 1);
		setUserList({ values });
	}

	function onTypeActivationCode(e) {
		setValue("code", e.target.value);
		if (validationCodeError) setValidationCodeError("");
	}

	function handleUserFirstNameChange(i, event) {
		let values = [...userList.values];
		values[i].firstName = event.target.value;
		setUserList({ values });
	}

	function handleUserLastNameChange(i, event) {
		let values = [...userList.values];
		values[i].lastName = event.target.value;
		setUserList({ values });
	}

	function handleUserEmailChange(i, event) {
		let values = [...userList.values];
		values[i].email = event.target.value;
		setUserList({ values });
	}
	function handleUserJobTitleChange(i, selectedOption) {
		let values = [...userList.values];
		values[i].jobTitle = selectedOption.value;
		setUserList({ values });
	}

	// const formData = watch();

	function createUserTable() {
		return userList.values.map((currentUser, i) => (
			<div key={i}>
				{console.log(currentUser.firstName)}
				<h4>User number {i + 1}</h4>
				<div>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`first_name_${i}`}>
								First Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`first_name_${i}`}
									name={`first_name_${i}`}
									placeholder='Charlie'
									value={currentUser.firstName}
									onChange={event => handleUserFirstNameChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`first_name_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`last_name_${i}`}>
								Last Name
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`last_name_${i}`}
									name={`last_name_${i}`}
									placeholder='Bailey'
									value={currentUser.lastName}
									onChange={event => handleUserLastNameChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`last_name_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`email_${i}`}>
								Email
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<FormInput
									type='text'
									id={`email_${i}`}
									name={`email_${i}`}
									placeholder='charlie.bailey@example.com'
									value={currentUser.email}
									onChange={event => handleUserEmailChange(i, event)}
									register={register}
									validations={{ required: true }}
								/>
								{errors[`email_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col className='align-self-center' md='3'>
							<label className='labels' htmlFor={`job_title_${i}`}>
								Job Title
							</label>
						</Col>
						<Col className='align-self-center' md='9'>
							<FormGroup>
								<Controller
									control={control}
									name={`jobTitle_${i}`}
									defaultValue='Estimator'
									render={({ field }) => (
										<Select
											{...field}
											styles={reactSelectStyles}
											placeholder='Job Title'
											options={[
												{ value: "Estimator", label: "Estimator" },
												{ value: "Shop Manager", label: "Shop Manager" },
												{
													value: "Shop Regional Manager",
													label: "Shop Regional Manager",
												},
												{ value: "Company Owner", label: "Company Owner" },
												{ value: "Technician", label: "Technician" },
											]}
											value={{ value: currentUser.jobTitle, label: currentUser.jobTitle }}
											onChange={
												(selectedOption) => {
													field.onChange(selectedOption);
													handleUserJobTitleChange(i, selectedOption);
												}
											}
										/>
									)}
								/>
								{errors[`job_title_${i}`] && (
									<Alert color='danger'>This field is required</Alert>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Button color='warning' type='button' onClick={removeClick.bind(i)}>
						Remove User
					</Button>
				</div>
				<br></br>
			</div>
		));
	}

	return (
		<>
			<VsstaHomeNavbar />
			<div className='wrapper' ref={wrapper}>
				<div style={{ margin: "150px" }}></div>
				<div className='section'>
					<Container>
						<div>
							{formSuccess ? (
								<Alert color='success'>
									Thank You For Signing Up With Vssta! You Will Recieve an Email
									Shortly Containing Log In Information.{" "}
								</Alert>
							) : null}
						</div>
						<Form onSubmit={handleSubmit(onSubmit)} id='form'>
							<Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
								<Col className='ml-auto' md='12'>
									<div className='section'>
										<header>
											<h2 className='text-uppercase'>Activation Code</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='code'>
													Code
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='code'
														name='code'
														placeholder='Activation Code'
														onChange={onTypeActivationCode}
														onBlur={checkValidationCode}
														register={register}
														validations={{ required: true }}
													/>
													{validationCodeValid && (
														<Alert color='success'>
															Activation code is valid!
														</Alert>
													)}
													{errors.code && (
														<Alert color='danger'>This field is required</Alert>
													)}
													{validationCodeError && (
														<Alert color='danger'>{validationCodeError}</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<p style={{ margin: "40px" }}></p>
										<header>
											<h2 className='text-uppercase'>Payables Information</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contactname'>
													Contact Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='contactname'
														name='contactname'
														placeholder='Name...'
														register={register}
														validations={{ required: true }}
													/>
													{errors.username && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contactphone'>
													Contact Phone
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='contactphone'
														name='contactphone'
														placeholder='740-548-000'
														register={register}
														validations={{ required: true }}
													/>
													{errors.username && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contactemail'>
													Email
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='contactemail'
														name='contactemail'
														placeholder='charlie.bailey@example.com'
														register={register}
														validations={{ required: true }}
													/>
													{errors.username && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<p style={{ margin: "40px" }}></p>
										<header>
											<h2 className='text-uppercase'>Company information</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='companyname'>
													Company Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='companyname'
														name='companyname'
														placeholder='Company Name...'
														register={register}
														validations={{ required: true }}
													/>
													{errors.username && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<p style={{ margin: "40px" }}></p>
										<header>
											<h2 className='text-uppercase'>Shop information</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='shop_name'>
													Shop Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='shop_name'
														name='shop_name'
														placeholder='Mikes Collision Center'
														register={register}
														validations={{ required: true }}
													/>
													{errors.shop_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='number'>
													Number
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='number'
														name='number'
														placeholder='380'
														register={register}
														validations={{ required: true }}
													/>
													{errors.number && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='street'>
													Street Address
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='street'
														name='street'
														placeholder='South Main Street'
														register={register}
														validations={{ required: true }}
													/>
													{errors["street"] && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='city'>
													City
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='city'
														name='city'
														placeholder='Houston'
														register={register}
														validations={{ required: true }}
													/>
													{errors.city && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='state'>
													State
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='state'
														name='state'
														placeholder='Texas'
														register={register}
														validations={{ required: true }}
													/>
													{errors.state && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='post_code'>
													Post Code
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='post_code'
														name='post_code'
														placeholder='43318'
														register={register}
														validations={{ required: true }}
													/>
													{errors.post_code && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='country'>
													Country
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='country'
														name='country'
														placeholder='United States'
														register={register}
														validations={{ required: true }}
													/>
													{errors.country && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='wifi_user'>
													Wifi User Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='wifi_user'
														name='wifi_user'
														placeholder='Wifi Username'
														register={register}
														validations={{ required: true }}
													/>
													{errors.wifi_user && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='wifi_pass'>
													Wifi Password
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='wifi_pass'
														name='wifi_pass'
														placeholder='Wifi Password'
														register={register}
														validations={{ required: true }}
													/>
													{errors.wifi_pass && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='scanner_serial'>
													Scanner Serial Number
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													{/* <p>{JSON.stringify(formData, null, 2)}</p> */}
													<FormInput
														type='text'
														id='scanner_serial'
														name='scanner_serial'
														placeholder='989770001***'
														register={register}
														validations={{ required: true, minLength: 12 }}
													/>
													{errors.scanner_serial && (
														<Alert color='danger'>
															{errors.scanner_serial.type === 'required' && 'This field is required'}
															{errors.scanner_serial.type === 'minLength' && 'Minimum length is 12'}
														</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='telephone'>
													Phone Number
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='telephone'
														name='telephone'
														placeholder='740-548-000'
														register={register}
														validations={{ required: true }}
													/>
													{errors.telephone && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='shop_email'>
													Shop Email
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='email'
														id='shop_email'
														name='shop_email'
														placeholder='charlie.bailey@example.com'
														register={register}
														validations={{ required: true }}
													/>
													{errors.shop_email && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels'>Management System</label>
											</Col>
											<Col className='align-self-center' md='4'>
												<FormGroup>
													<Controller
														control={control}
														name='managementSystem'
														defaultValue=''
														render={({ field }) => (
															<Select
																{...field}
																styles={reactSelectStyles}
																placeholder='Management System'
																options={[
																	{ value: "None", label: "None" },
																	{ value: "Rome", label: "Rome" },
																	{ value: "CCC One", label: "CCC One" },
																	{ value: "CC3", label: "CC3" },
																	{ value: "Mitchell", label: "Mitchell" },
																	{ value: "Nexysis", label: "Nexysis" },
																]}
															/>
														)}
													/>
													{errors.managementSystem && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<p style={{ margin: "40px" }}></p>
										<header>
											<h2 className='text-uppercase'>User Information</h2>
										</header>
										<hr className='line-info' />
										<br></br>
										{createUserTable()}
										<Button
											color='info'
											type='button'
											value='Add user'
											onClick={addClick}
										>
											Add user
										</Button>
										<Row className='mt-4'>
											<Col md='6'>
												<Button
													color='info'
													type='submit'
													onClick={handleSubmit(onSubmit)}
												>
													Submit Ticket
												</Button>
												<Button
													className='btn-simple ml-1'
													color='info'
													type='button'
												>
													Cancel
												</Button>
											</Col>
										</Row>
										<div>
											{loadingSpinner ? (
												<Spinner
													animation='border'
													color='success'
													style={{ marginTop: "10px", textAlign: "center" }}
												/>
											) : null}
										</div>
									</div>
								</Col>
							</Row>
						</Form>
					</Container>
				</div>
			</div>
			<div style={{ margin: "250px" }}></div>
		</>
	);
}
