import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import CookieConsent, {
	getCookieConsentValue,
	Cookies,
} from "react-cookie-consent";
import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.2.0";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";

// views/pages
import AboutUs from "views/pages/AboutUs.js";
import ContactUs from "views/pages/ContactUs.js";
import Success from "views/pages/Success.js";
import Pricing from "views/pages/Pricing.js";
import HomePage from "views/pages/HomePage.js";
import AdasCertification from "views/pages/AdasCertification.js";
import TechSupport from "views/pages/TechSupport.js";
import Leads from "views/pages/Leads.js";
import SignUpTicket from "views/pages/SignUpTicket.js";
import Privacy from "views/pages/Privacy";

const handleAcceptCookie = () => {
	// utility function for user accepting cookies for the site.
	if (
		process.env.REACT_APP_GOOGLE_ANALYTICS_ID &&
		process.env.REACT_APP_PRODUCTION == "true"
	) {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
	}
};

function App() {
	const location = useLocation();

	useEffect(() => {
		// Only register google analytics if user has accepted cookies, and calculate only when location changes
		if (
			getCookieConsentValue() === "true" &&
			process.env.REACT_APP_PRODUCTION == "true"
		) {
			handleAcceptCookie();
			ReactGA.set({ page: location.pathname }); // update user's current page
			ReactGA.send({
				hitType: "pageview",
				page: location.pathname,
				title: location.pathname,
			}); // record a pageview for the given page
		}
	}, [location]);

	return (
		<div>
			<Switch>
				<Route path='/vssta'>
					<HomePage />
				</Route>
				<Route path='/about-us'>
					<AboutUs />
				</Route>
				<Route path='/contact-us'>
					<ContactUs />
				</Route>
				<Route path='/success'>
					<Success />
				</Route>
				<Route path='/pricing'>
					<Pricing />
				</Route>
				<Route path='/adas_certification'>
					<AdasCertification />
				</Route>
				<Route path='/support'>
					<TechSupport />
				</Route>
				<Route path='/leads'>
					<Leads />
				</Route>
				<Route path='/signup-ticket'>
					<SignUpTicket />
				</Route>
				<Route path='/privacy'>
					<Privacy />
				</Route>
				<Redirect from='/' to='/vssta' />
			</Switch>
			<ToastContainer />
		</div>
	);
}

export default function AppWithRouter() {
	const handleDeclineCookie = () => {
		Cookies.remove("_ga");
		Cookies.remove("_gat");
		Cookies.remove("_gid");
	};

	return (
		<div className='App'>
			<BrowserRouter>
				<App />
				<CookieConsent
					enableDeclineButton
					onAccept={handleAcceptCookie}
					onDecline={handleDeclineCookie}
					style={{ background: "#2B373B", fontSize: "20px", padding: "20px" }}
					buttonStyle={{
						color: "#4e503b",
						fontSize: "20px",
						padding: "10px 20px",
					}}
					declineButtonStyle={{
						color: "#ffffff",
						fontSize: "20px",
						padding: "10px 20px",
					}}
				>
					This website uses cookies to enhance the user experience.
				</CookieConsent>
			</BrowserRouter>
		</div>
	);
}
