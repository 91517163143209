import React, { useState, useEffect } from "react";
import axios from "../../utils/Axios";
import { useForm } from "react-hook-form";
import {
	FormGroup,
	Form,
	Input,
	InputGroup,
	InputGroupText,
	Button,
	Row,
	Col,
} from "reactstrap";

function Calculator() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const [prescanPrice, setPrescanPrice] = useState(40);
	const [postscanPrice, setPostscanPrice] = useState(50);
	const [carCount, setCarCount] = useState(100);
	const [email, setEmail] = useState("");
	const [currentScanProvider, setCurrentScanProvider] = useState("");
	const [total, setTotal] = useState(0);

	// preScan price
	// postScan price
	// car count
	// email
	// current scan provider input
	// send me results button
	// button to clear
	// display results button OR dynamic <<< Preffered

	function resetResults() {
		setPrescanPrice(0);
		setPostscanPrice(0);
		setCarCount(0);
	}

	useEffect(() => {
		setTotal(
			Number(carCount) * (Number(prescanPrice) + Number(postscanPrice)) - 1000
		);
	}, [prescanPrice, postscanPrice, carCount]);

	function onSubmit(data) {
		console.log(data);

		// http://127.0.0.1:8000/activation/activate_user
		axios
			.post("/activation/calculator_results", data)
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	return (
		<div>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<FormGroup>
					<Row xs='1' md='2'>
						<Col>
							<h2>Pre-Scan Price</h2>
						</Col>
						<Col>
							<InputGroup style={{ marginBottom: "2px" }}>
								<InputGroupText>$</InputGroupText>
								<Input
									type='number'
									name='prescanPrice'
									id='prescanPrice'
									value={prescanPrice}
									{...register("prescanPrice", { required: true })}
									onChange={(e) => setPrescanPrice(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<Input
									min={0}
									max={80}
									type='range'
									value={prescanPrice}
									{...register("prescanPrice", { required: true })}
									onChange={(e) => setPrescanPrice(e.target.value)}
									style={{ width: "100%" }}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row xs='1' md='2'>
						<Col>
							<h2>Post-Scan Price</h2>
						</Col>
						<Col>
							<InputGroup style={{ marginBottom: "2px" }}>
								<InputGroupText>$</InputGroupText>
								<Input
									type='number'
									name='postscanPrice'
									id='postscanPrice'
									value={postscanPrice}
									{...register("postscanPrice", { required: true })}
									className='h3'
									onChange={(e) => setPostscanPrice(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<Input
									min={0}
									max={80}
									type='range'
									value={postscanPrice}
									{...register("postscanPrice", { required: true })}
									onChange={(e) => setPostscanPrice(e.target.value)}
									style={{ width: "100%" }}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row xs='1' md='2'>
						<Col>
							<h2>Car Count</h2>
						</Col>
						<Col>
							<InputGroup style={{ marginBottom: "2px" }}>
								<InputGroupText>$</InputGroupText>
								<Input
									type='number'
									name='carCount'
									id='carCount'
									value={carCount}
									{...register("carCount", { required: true })}
									onChange={(e) => setCarCount(e.target.value)}
								/>
							</InputGroup>
							<InputGroup>
								<Input
									min={0}
									max={200}
									type='range'
									value={carCount}
									{...register("carCount", { required: true })}
									onChange={(e) => setCarCount(e.target.value)}
									style={{ width: "100%" }}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row xs='1' md='2'>
						<Col>
							<h2>Scan Provider</h2>
						</Col>
						<Col>
							<InputGroup>
								<Input
									type='text'
									name='currentScanProvider'
									id='currentScanProvider'
									placeholder='Enter your Current Scan Provider'
									value={currentScanProvider}
									{...register("currentScanProvider", { required: true })}
									onChange={(e) => setCurrentScanProvider(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row xs='1' md='2'>
						<Col>
							<h2>Email Address</h2>
						</Col>
						<Col>
							<InputGroup>
								<Input
									type='text'
									name='email'
									id='email'
									placeholder='Enter your Email Address'
									value={email}
									{...register("email", { required: true })}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</InputGroup>
						</Col>
					</Row>

					<Row xs='1' md='2'>
						<Col>
							<h1 className='display-2'>Total</h1>
						</Col>
						<Col>
							<Input
								type='hidden'
								name='total'
								id='total'
								value={total}
								{...register("total", { required: true })}
								onChange={(e) => setTotal(e.target.value)}
							/>
							<h2 className='display-2'>${total}</h2>
						</Col>
					</Row>

					<Row xs='1'>
						<Col>
							<Button type='submit' onClick={handleSubmit(onSubmit)}>
								Email me these Results
							</Button>
						</Col>
						<Col>
							<Button onClick={resetResults}>Clear Results</Button>
						</Col>
					</Row>
				</FormGroup>
			</Form>
		</div>
	);
}

export default Calculator;
