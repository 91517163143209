import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { toast } from "react-toastify";

// reactstrap components
import {
	Alert,
	Button,
	FormGroup,
	Form,
	Container,
	Row,
	Col,
} from "reactstrap";

// core components
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import FormInput from "components/Forms/FormInput";

import axios from "../../utils/Axios";
import { reactSelectStyles } from "../../consts";

export default function Leads() {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const [email, setEmail] = useState("");

	const wrapper = React.useRef(null);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		wrapper.current.scrollTop = 0;
		document.body.classList.add("account-settings");
		return function cleanup() {
			document.body.classList.remove("account-settings");
		};
	}, []);

	function onSubmit(data) {
		console.log(data);
		axios
			.post("new_lead", data)
			.then((res) => {
				toast.success("Lead Submitted Successfully!");
				document.getElementById("form").reset();
			})
			.catch((error) => {
				toast.error("Error Submitting Lead! Please check all form fields and submit again.", {
					position: "bottom-right",
				});
			});
	}
	return (
		<>
			<VsstaHomeNavbar />
			<div className='wrapper' ref={wrapper}>
				<div style={{ margin: "150px" }}></div>
				<div className='section'>
					<Container>
						<Form onSubmit={handleSubmit(onSubmit)} id='form'>
							<Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
								<Col className='ml-auto' md='12'>
									<div className='section'>
										<header>
											<h2 className='text-uppercase'>Partner information</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label
													className='labels'
													htmlFor='partner_company_name'
												>
													Partner Company Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='partner_company_name'
														name='partner_company_name'
														placeholder='Partner Company Name'
														register={register}
														validations={{ required: true }}
													/>
													{errors.partner_company_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='first_name'>
													First Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='first_name'
														name='first_name'
														placeholder='Charlie'
														register={register}
														validations={{ required: true }}
													/>
													{errors.first_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='last_name'>
													Last Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='last_name'
														name='last_name'
														placeholder='Bailey'
														register={register}
														validations={{ required: true }}
													/>
													{errors.last_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='email'>
													Business Email
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='email'
														name='email'
														id='email'
														placeholder='charlie.bailey@example.com'
														register={register}
														validations={{ required: true }}
														value={email}
														onChange={(e) => setEmail(e.target.value)}
													/>
													{errors.email && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<p style={{ margin: "100px" }}></p>
										<header>
											<h2 className='text-uppercase'>
												Referred Company information
											</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='company_name'>
													Company Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='company_name'
														name='company_name'
														placeholder='Mikes Collision Center'
														register={register}
														validations={{ required: true }}
													/>
													{errors.company_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contact_person'>
													Contact Person
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='contact_person'
														name='contact_person'
														placeholder='Bill Wilson'
														register={register}
														validations={{ required: true }}
													/>
													{errors.contact_person && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contact_phone'>
													Contact Phone
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='contact_phone'
														name='contact_phone'
														placeholder='1-800-177-2334'
														register={register}
														validations={{ required: true }}
													/>
													{errors.contact_person && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='contact_email'>
													Contact Email
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='email'
														id='contact_email'
														name='contact_email'
														placeholder='charlie.bailey@example.com'
														register={register}
														validations={{ required: true }}
													/>
													{errors.contact_email && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#address'>
													Address
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='address'
														name='address'
														placeholder='455 South Main Street'
														register={register}
														validations={{ required: true }}
													/>
													{errors["address"] && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='city'>
													City
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='city'
														name='city'
														placeholder='Houston'
														register={register}
														validations={{ required: true }}
													/>
													{errors.city && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='state'>
													State
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='state'
														name='state'
														placeholder='Texas'
														register={register}
														validations={{ required: true }}
													/>
													{errors.state && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='post_code'>
													Post Code
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='post_code'
														name='post_code'
														placeholder='43318'
														register={register}
														validations={{ required: true }}
													/>
													{errors.post_code && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='country'>
													Country
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='country'
														name='country'
														placeholder='United States'
														register={register}
														validations={{ required: true }}
													/>
													{errors.country && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='car_count'>
													Monthly Car Count
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														id='car_count'
														name='car_count'
														placeholder='300'
														register={register}
														validations={{ required: true }}
													/>
													{errors.car_count && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels'>Type of Lead</label>
											</Col>
											<Col className='align-self-center' md='4'>
												<FormGroup>
													<Controller
														name='leadType'
														control={control}
														render={({ field }) => (
															<Select
																{...field}
																placeholder='Select Lead Type'
																styles={reactSelectStyles}
																options={[
																	{
																		value: "Vssta Scanning",
																		label: "Vssta Scanning",
																	},
																	{
																		value: "Equipment",
																		label: "Equipment",
																	},
																	{
																		value: "Vssta Scanning + Equipment",
																		label: "Vssta Scanning + Equipment",
																	},
																]}
															/>
														)}
													></Controller>
													{errors.leadType && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels'>Current Vendor</label>
											</Col>
											<Col className='align-self-center' md='4'>
												<FormGroup>
													<Controller
														name='currentVendor'
														control={control}
														render={({ field }) => (
															<Select
																{...field}
																placeholder='Select Current Vendor'
																styles={reactSelectStyles}
																options={[
																	{ value: "None", label: "None" },
																	{ value: "Astech", label: "Astech" },
																	{ value: "Airpro", label: "Airpro" },
																	{ value: "Autel", label: "Autel" },
																	{ value: "Snap On", label: "Snap On" },
																	{ value: "DrewTech", label: "DrewTech" },
																	{ value: "Other", label: "Other" },
																]}
															/>
														)}
													/>
													{errors.currentVendor && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='notes'>
													Any notes for opportunity
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='textarea'
														id='notes'
														name='notes'
														placeholder='Shop could use help .....'
														register={register}
														validations={{ required: true }}
													/>
													{errors.notes && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='12'>
												<FormGroup style={{ paddingLeft: "20px" }}>
													<FormInput
														type='checkbox'
														id='terms'
														name='terms'
														register={register}
														validations={{ required: true }}
														className='react-select react-select-info'
														placeholder='Terms and Conditions'
													/>
													<label htmlFor='terms' className='labels'>
														Terms: By submitting this form, you agree to the
														VSSTA Vehicle Scanning Solutions, LLC Partner
														Program Terms as applicable based on your program
														eligibility and your submitted request. Leads are
														valid for 30 days. It is the sole responsibility of
														the partner to resubmit lead if still working after
														30 days. Submitting lead form is not a confirmation
														of referral. Lead will be evaluated and VSSTA will
														confirm lead has not already been submitted by
														another partner. You will receive confirmation of
														valid lead.
													</label>

													{errors.terms && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row className='mt-4'>
											<Col md='6'>
												<Button
													color='info'
													type='submit'
													onClick={handleSubmit(onSubmit)}
												>
													Send
												</Button>
												<Button
													className='btn-simple ml-1'
													color='info'
													type='button'
												>
													Cancel
												</Button>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Form>
					</Container>
				</div>
			</div>
			<div style={{ margin: "250px" }}></div>
		</>
	);
}
