import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
// nodejs library that concatenates classes
import classnames from "classnames";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import PayPal from "components/PayPal";
// reactstrap components
import {
	Alert,
	Button,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
	FormText,
} from "reactstrap";

// core components
import VsstaHomeNavbar from "components/Navbars/VsstaHomeNavbar.js";
import FormInput from "components/Forms/FormInput";

import { reactSelectStyles } from "../../consts";

export default function AdasCertification() {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm();
	const [shopEmail, setShopEmail] = useState("");
	const [uploadFile, setUploadFile] = React.useState();
	const [loadFileText, setloadFileText] = React.useState("Load File");
	const [checkout, setChceckout] = useState(false);
	const [validatedData, setValidatedData] = useState("");

	const wrapper = React.useRef(null);
	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		wrapper.current.scrollTop = 0;
		document.body.classList.add("account-settings");
		return function cleanup() {
			document.body.classList.remove("account-settings");
		};
	}, []);

	function onSubmit(data) {
		// TODO -> validate a data if email is email etc

		setChceckout(true);
		setValidatedData({
			file: data.files[0],
			shop_name: data.shop_name,
			city: data.city,
			country: data.country,
			number: data.number,
			post_code: data.post_code,
			state: data.state,
			street: data.street,
			tech_email: data.tech_email,
			tech_first_name: data.tech_first_name,
			tech_last_name: data.tech_last_name,
			tech_phone: data.tech_phone,
			cert_type: data.cert_type,
		});
	}

	return (
		<>
			<VsstaHomeNavbar />
			<div className='wrapper' ref={wrapper}>
				<div style={{ margin: "100px", textAlign: "center" }}></div>
				<div className='section'>
					<Container>
						<h4
							style={{
								textAlign: "left",
								border: "3px solid  #2bffc6",
								borderRadius: "10px",
								padding: "15px",
								marginBottom: "-10px",
							}}
						>
							VSSTA is proud to announce our ADAS certification program. Basic
							Certification requires a minimum of 50 calibrations in order to
							apply. Basic Certification is made up of 35 questions and will
							take 40-60 minutes to complete. Master Certification requires a
							minimum of 1,000 calibrations in order to apply. Master
							Certification is made up of 100 questions and will take an average
							of 2 hours to complete. Upon successful completion of the test you
							will receive a framed plaque with a certificate. Master
							certification will also receive a medal of completion. VSSTA's
							ADAS certification program is only valid with VSSTA equipment.
							Certification program is good for both X431 mobile and X431 Pro
							ADAS units.
						</h4>
						<Form onSubmit={handleSubmit(onSubmit)} id='form'>
							<Row style={{ paddingLeft: "100px", paddingRight: "100px" }}>
								<Col className='ml-auto' md='12'>
									<div className='section'>
										<p style={{ margin: "5px" }}></p>
										<header>
											<h2 className='text-uppercase'>Shop information</h2>
										</header>
										<hr className='line-info' />
										<br />
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#shop_name'>
													Shop Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='shop_name'
														placeholder='Mikes Collision Center'
														register={register}
														validations={{ required: true }}
													/>
													{errors.shop_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#number'>
													Number
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='number'
														placeholder='380'
														register={register}
														validations={{ required: true }}
													/>
													{errors.number && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#street'>
													Street Address
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='street'
														placeholder='South Main Street'
														register={register}
														validations={{ required: true }}
													/>
													{errors["street"] && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#city'>
													City
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='city'
														placeholder='Houston'
														register={register}
														validations={{ required: true }}
													/>
													{errors.city && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#state'>
													State
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='state'
														placeholder='Texas'
														register={register}
														validations={{ required: true }}
													/>
													{errors.state && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#post_code'>
													Post Code
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='post_code'
														placeholder='43318'
														register={register}
														validations={{ required: true }}
													/>
													{errors.post_code && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#country'>
													Country
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='country'
														placeholder='United States'
														register={register}
														validations={{ required: true }}
													/>
													{errors.country && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#tech_first_name'>
													Tech First Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='tech_first_name'
														placeholder='Bill'
														register={register}
														validations={{ required: true }}
													/>
													{errors.tech_first_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#tech_last_name'>
													Tech Last Name
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='tech_last_name'
														placeholder='Porter'
														register={register}
														validations={{ required: true }}
													/>
													{errors.tech_last_name && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#tech_phone'>
													Tech Phone
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='tech_phone'
														placeholder='1-800-223-1998'
														register={register}
														validations={{ required: true }}
													/>
													{errors.tech_phone && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#tech_email'>
													Tech Email
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='text'
														name='tech_email'
														placeholder='bill@gmail.com'
														register={register}
														validations={{ required: true }}
													/>
													{errors.tech_email && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='12'>
												<FormGroup>
													<label
														htmlFor='terms'
														className='labels'
														style={{
															textAlign: "left",
															border: "3px solid  #1d8cf8",
															borderRadius: "10px",
															padding: "15px",
														}}
													>
														Disclaimer: 50 Calibrations required for Basic.
														1,000 calibrations and Basic Certification required
														before taking Master Certification.
													</label>

													{errors.terms && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels'>Certification Type</label>
											</Col>
											<Col className='align-self-center' md='4'>
												<FormGroup>
													<Controller
														name='cert_type'
														control={control}
														render={({ field }) => (
															<Select
																{...field}
																styles={reactSelectStyles}
																required={true}
																options={[
																	{ value: "Basic", label: "Basic" },
																	{ value: "Master", label: "Master" },
																]}
															/>
														)}
													/>
													{errors.cert_type && (
														<Alert color='danger'>This field is required</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col className='align-self-center' md='3'>
												<label className='labels' htmlFor='#file'>
													Upload File For Calibration Verification
												</label>
											</Col>
											<Col className='align-self-center' md='9'>
												<FormGroup>
													<FormInput
														type='file'
														name='files'
														placeholder='Upload File'
														register={register}
														validations={{ required: true }}
														onChange={(e) => {
															setUploadFile(e.target.files);
															setloadFileText(e.target.files[0].name);
														}}
													/>
													<FormText>
														Please upload a file for calibration verification
													</FormText>
													{errors.files && (
														<Alert color='danger'>Please upload file</Alert>
													)}
												</FormGroup>
											</Col>
										</Row>
										<Row className='mt-4'>
											<div className='mx-auto'>
												<Col className=''>
													{!checkout ? (
														<Button
															className='btn btn-lg btn-block'
															color='info'
															type='submit'
															onClick={handleSubmit(onSubmit)}
														>
															Submit
														</Button>
													) : (
														<Row>
															<h3>Please choose payment option to continue</h3>
														</Row>
													)}
												</Col>
											</div>
										</Row>
										<Row className=' justify-content-md-center'>
											<Col md='6'>
												{checkout ? <PayPal data={{ validatedData }} /> : null}
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Form>
					</Container>
				</div>
			</div>
			<div style={{ margin: "250px" }}></div>
		</>
	);
}
