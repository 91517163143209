import React from "react";
import Select from "react-select";
import { Button, FormGroup, Form, Input, Row, Col } from "reactstrap";

import { reactSelectStyles } from "../../consts";

export default class TechSupportForm extends React.Component {
	constructor(props) {
		super(props);
		this.submitForm = this.submitForm.bind(this);
		this.state = {
			status: "",
			firstName: "",
			lastName: "",
			email: "",
			company_name: "",
			shop_name: "",
			shop_address: "",
			phone: "",
			jobTitle: "",
		};
	}
	render() {
		return (
			<Form
				id='tech-form-1'
				method='post'
				role='form'
				onSubmit={this.submitForm}
				action='https://formspree.io/f/xknpygpe'
			>
				<header>
					<h2 className='text-uppercase'>Tech Support Form</h2>
				</header>
				<hr className='line-info' />
				<br />
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#firstName'>
							First Name
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='Charlie'
								id='firstName'
								name='firstName'
								required='required'
								type='text'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#lastName'>
							Last Name
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='Bailey'
								id='lastName'
								name='lastName'
								required='required'
								type='text'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#email'>
							Email
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='charlie.bailey@example.com'
								id='email'
								name='email'
								required='required'
								type='email'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#company_name'>
							Company Name
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='Millers Auto Group'
								id='company_name'
								name='company_name'
								required='required'
								type='text'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#shop_name'>
							Shop Name
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='Miller Collision Center'
								id='shop_name'
								name='shop_name'
								required='required'
								type='text'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#shop_address'>
							Shop Address
						</label>
					</Col>
					<Col className='align-self-center' md='9'>
						<FormGroup>
							<Input
								placeholder='290 South Main Street'
								id='shop_address'
								name='shop_address'
								required='required'
								type='text'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels' htmlFor='#phone'>
							Phone Number
						</label>
					</Col>
					<Col className='align-self-center' md='4'>
						<FormGroup>
							<Input
								placeholder='740-456-8978'
								id='phone'
								name='phone'
								required='required'
								type='tel'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col className='align-self-center' md='3'>
						<label className='labels'>Job Title</label>
					</Col>
					<Col className='align-self-center' md='4'>
						<FormGroup>
							<Select
								styles={reactSelectStyles}
								placeholder='Shop Owner'
								name='jobTitle'
								options={[
									{ value: "", label: "Company Owner" },
									{ value: "2", label: "Shop Owner" },
									{ value: "3", label: "Shop Manager" },
									{ value: "4", label: "Technician" },
									{ value: "4", label: "Estimator" },
								]}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mt-4'>
					<Col md='6'>
						<Button color='info'>Send Support Ticket</Button>
						<Button className='btn-simple ml-1' color='info'>
							Cancel
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}

	submitForm(ev) {
		ev.preventDefault();
		const form = ev.target;
		const data = new FormData(form);
		const xhr = new XMLHttpRequest();
		xhr.open(form.method, form.action);
		xhr.setRequestHeader("Accept", "application/json");
		xhr.onreadystatechange = () => {
			if (xhr.readyState !== XMLHttpRequest.DONE) return;
			if (xhr.status === 200) {
				form.reset();
				this.setState({ status: "SUCCESS" });
			} else {
				this.setState({ status: "ERROR" });
			}
		};
		xhr.send(data);
	}
}
