import { Input } from "reactstrap";

// utility component for making reactstrap (v9) form work with react-hook-form (v7)
const FormInput = ({ register, name, validations, ...rest }) => {
	const { ref, ...registerField } = register(name, validations);

	return <Input innerRef={ref} {...registerField} {...rest} />;
};

export default FormInput;
